import { Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

interface EventChipProps {
  children: ReactNode;
}

const EventChip: FC<EventChipProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      textAlign={"center"}
      borderRadius={5}
      px={2}
      border={"1px solid" + theme.palette.grey[600]}
      display={"flex"}
      justifyContent={"center"}
      height={25}
      alignItems={"center"}
    >
      {children}
    </Typography>
  );
};

export default EventChip;
