import { Stack } from "@mui/material";
import "dayjs/locale/fr";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import EventContext from "../../../../context/EventsContext";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import { EventType } from "../../../../shared/types/events";
import usePrevious from "../../../../hook/usePrevious";
import useDrivePicker from "react-google-drive-picker";
import Attachement from "./Attachement/Attachement";
import PreferenceContext from "../../../../context/PreferenceContext";

interface EventDetailGeneralProps {}

const EventDetailSummary: FunctionComponent<EventDetailGeneralProps> = () => {
  const { selectedEvent: selectedEventValue, setSelectedEvent } =
    useContext(EventContext);
  const selectedEvent = selectedEventValue as EventType;

  const prevEvent = usePrevious(selectedEvent._id);

  const [initialValue, setInitialValue] = useState<string>(
    selectedEvent.summary
  );

  const { googleAuth, setGoogleAuth } = useContext(PreferenceContext);

  const [openPicker, authResponse] = useDrivePicker();

  const clientId = useMemo(() => {
    return process.env.REACT_APP_CLIENT_ID;
  }, []);

  useEffect(() => {
    if (prevEvent !== selectedEvent._id) {
      setInitialValue(selectedEvent.summary);
    }
  }, [selectedEvent, prevEvent]);

  function handleChange(v: string) {
    setSelectedEvent({ ...selectedEvent, summary: v });
  }

  useEffect(() => {
    if (authResponse) {
      setGoogleAuth(authResponse);
    }
  }, [authResponse, setGoogleAuth]);

  const handleOpenPicker = async () => {
    try {
      openPicker({
        clientId: clientId ? clientId : "",
        developerKey: "",
        token:
          googleAuth && googleAuth.expires_in > 0
            ? googleAuth.access_token
            : undefined,
        setParentFolder: "root",
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        setIncludeFolders: true,
        locale: "fr",
        callbackFunction: (data) => {
          if (data.action === "picked") {
            const newAttachment = {
              iconUrl: data.docs[0].iconUrl,
              fileUrl: data.docs[0].url,
              fileName: data.docs[0].name,
              mimeType: data.docs[0].mimeType,
            };
            setSelectedEvent({
              ...selectedEvent,
              attachments: [...selectedEvent.attachments, newAttachment],
            });
          }
        },
      });
    } catch (error) {
      console.error("Error refreshing token or opening picker:", error);
    }
  };

  return (
    <Stack spacing={1} flexGrow={1} overflow={"auto"}>
      {selectedEvent.attachments.length > 0 && (
        <Stack direction={"row"} spacing={1} overflow={"auto"}>
          {selectedEvent.attachments.map((v, k) => {
            return <Attachement key={k} attachement={v}></Attachement>;
          })}
        </Stack>
      )}
      <TextEditor
        onAttachementClick={handleOpenPicker}
        initialValue={initialValue}
        onChangeCompleted={handleChange}
      />
    </Stack>
  );
};

export default EventDetailSummary;
