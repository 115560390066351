import {
  Box,
  Fade,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FunctionComponent, useContext, useMemo, useRef } from "react";
import { format } from "date-fns";
import { EventType } from "../../../../shared/types/events";
import ArticleIcon from "@mui/icons-material/Article";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import JobBox from "./JobBox/JobBox";
import PreferenceContext from "../../../../context/PreferenceContext";
import AnalyticBox from "./AnalyticBox/AnalyticBox";
import SavingsIcon from "@mui/icons-material/Savings";
import EventChip from "../../../../components/EventChip/EventChip";
import { InsertDriveFile } from "@mui/icons-material";

interface EventLayoutProps {
  onClick(newValue: any): void;
  selected: boolean;
  openDetail: boolean;
  event: EventType;
}

const EventBox: FunctionComponent<EventLayoutProps> = (props) => {
  const theme = useTheme();

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { userPreference } = useContext(PreferenceContext);

  const { event } = { ...props };

  const jobList = useMemo(() => {
    const newArray: string[] = [];
    event.teams.map((v, i) => {
      if (newArray.indexOf(v.job) === -1) {
        newArray.push(v.job);
      }
    });
    return newArray;
  }, [event]);

  const formatDate = (date: Date) => {
    return format(date, "dd/MM . HH:mm");
  };

  return (
    <div onClick={props.onClick} aria-hidden="true">
      <Fade in={true}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 1,
            py: 1,
            rowGap: 1,
            borderRadius: 2,
            bgcolor: theme.palette.background.paper,
            cursor: "pointer",
            transition: "box-shadow 0.1s ease",
            boxShadow: props.selected
              ? "0px 0px 0px 2px" + theme.palette.primary.main
              : "",
            "&:hover": {
              boxShadow: props.selected
                ? ""
                : `0px 0px 0px 1px ${theme.palette.primary.light}`,
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              },
            }}
            overflow={"auto"}
            spacing={1}
          >
            <Stack flexGrow={1} overflow={"auto"}>
              <Typography
                fontWeight={800}
                color={theme.palette.grey[600]}
                fontSize={"1.2em"}
                whiteSpace={"nowrap"}
                textTransform={"uppercase"}
              >
                {event.name}
              </Typography>
              <Typography
                fontSize={"0.8em"}
                whiteSpace={"nowrap"}
                color={theme.palette.grey[600]}
                fontWeight={100}
              >
                <span style={{ fontWeight: 700 }}>#{event.accountingId}</span>
                {" | "}
                <span
                  style={{ color: theme.palette.primary.main, fontWeight: 700 }}
                >
                  {event.locationInfo
                    ? event.locationInfo.name
                    : event.locationName}{" "}
                </span>
                | {formatDate(event.startDate)}
                {" | "}
                {formatDate(event.endDate)}
              </Typography>
            </Stack>
            <Stack direction={isMobile ? "row-reverse" : "row"} spacing={1}>
              {event.financeFile && (
                <EventChip>
                  <SavingsIcon fontSize="small" />
                </EventChip>
              )}
              {event.isBilled && (
                <EventChip>
                  <AttachMoneyIcon fontSize="small" />
                </EventChip>
              )}
              {event.summary &&
                event.summary !== `<p class="editor-paragraph"><br></p>` && (
                  <EventChip>
                    <ArticleIcon fontSize="small" />
                  </EventChip>
                )}
              {event.attachments.length > 0 && (
                <EventChip>
                  <InsertDriveFile fontSize="small" />
                </EventChip>
              )}
              <Typography
                fontSize={"0.7rem"}
                whiteSpace={"nowrap"}
                textAlign={"center"}
                textTransform={"uppercase"}
                borderRadius={5}
                px={2}
                py={0.5}
                border={"1px solid" + theme.palette.grey[600]}
              >
                {event.projectManager}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "flex",
              flexGrow: 1,
              overflow: "auto",
            }}
          >
            <Box
              ref={scrollContainerRef}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                overflow: "auto",
                flexWrap: "wrap",
                flexGrow: 1,
              }}
            >
              {userPreference.view === "team" &&
                jobList.map((job, jobIndex) => {
                  const jobMissing =
                    event.teams.findIndex(
                      (v) => v.job === job && !v.confirmed
                    ) !== -1;
                  const memberList = event.teams.filter((v, i) => {
                    return v.job === job;
                  });
                  return (
                    <JobBox
                      key={jobIndex}
                      job={job}
                      jobMissing={jobMissing}
                      memberList={memberList}
                    ></JobBox>
                  );
                })}
              {userPreference.view === "analytic" && (
                <Stack spacing={1} flexGrow={1}>
                  {event.analytics.map((v, k) => {
                    return <AnalyticBox key={k} analytic={v} />;
                  })}
                </Stack>
              )}
            </Box>
          </Stack>
        </Paper>
      </Fade>
    </div>
  );
};

export default EventBox;
