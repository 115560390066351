import { Stack } from "@mui/material";
import FilterButton from "../../../components/FilterButton/FilterButton";

const ProjectList = () => {
  return (
    <Stack>
      <Stack direction={"row"} mx={1} alignItems={"center"}>
        <Stack
          direction={"row"}
          columnGap={"10px"}
          flexGrow={1}
          overflow={"auto"}
          alignItems={"center"}
        >
          <FilterButton>Tous</FilterButton>
          <FilterButton>Tous</FilterButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProjectList;
