import {
  CircularProgress,
  Divider,
  Hidden,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import DashboardBase from "../../components/base/DashboardBase/DashboardBase";
import { useState } from "react";
import WorkspaceBase from "../../components/base/WorkspaceBase/WorkspaceBase";
import ProjectList from "./ProjectList/ProjectList";

const ProjectLayout = () => {
  const [selectedFilter, setSelectedFilter] = useState(0);

  const [projectList, setProjectList] = useState([]);

  const theme = useTheme();

  return (
    <DashboardBase>
      <Tabs value={selectedFilter}>
        <Tab sx={{ width: "10vw", whiteSpace: "nowrap" }} label={"actifs"} />
        <Tab sx={{ width: "10vw", whiteSpace: "nowrap" }} label={"Archives"} />
        <Tab sx={{ width: "10vw", whiteSpace: "nowrap" }} label={"Corbeille"} />
      </Tabs>
      <WorkspaceBase>
        <Stack
          direction={"column"}
          sx={{
            width: "65%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          {projectList === null ? (
            <Stack
              flexGrow={1}
              m={3}
              spacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <ProjectList />
          )}
        </Stack>
        <Hidden mdDown>
          <>
            <Divider orientation="vertical" />
            <Stack
              style={{
                width: "35%",
              }}
            />
          </>
        </Hidden>
      </WorkspaceBase>
    </DashboardBase>
  );
};

export default ProjectLayout;
