import { createContext, useState, ReactNode, useEffect } from "react";
import React from "react";
import { preferenceType } from "../shared/types/preference";
import { defaultPreference, defaultUser } from "../shared/default";
import { authResult } from "react-google-drive-picker/dist/typeDefs";

interface PreferenceContextType {
  userPreference: preferenceType;
  setUserPreference(value: preferenceType): void;
  googleAuth: authResult | undefined;
  setGoogleAuth: React.Dispatch<React.SetStateAction<authResult | undefined>>;
}

const PreferenceContext = createContext<PreferenceContextType>(null!);

interface PreferenceContextProviderProps {
  children: ReactNode;
}

export const PreferenceContextProvider: React.FC<
  PreferenceContextProviderProps
> = ({ children }) => {
  const [userPreference, setUserPref] =
    useState<preferenceType>(defaultPreference);

  const [googleAuth, setGoogleAuth] = useState<authResult | undefined>();

  useEffect(() => {
    const item = localStorage.getItem("gozuprod");
    if (item) {
      const preferenceObject = JSON.parse(item);
      setUserPreference(preferenceObject);
    }
  }, []);

  function setUserPreference(prefObject: preferenceType) {
    localStorage.setItem("gozuprod", JSON.stringify(prefObject));
    setUserPref(prefObject);
  }

  return (
    <PreferenceContext.Provider
      value={{
        userPreference,
        setUserPreference,
        googleAuth,
        setGoogleAuth,
      }}
    >
      {children}
    </PreferenceContext.Provider>
  );
};

export default PreferenceContext;
