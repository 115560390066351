import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

import "dayjs/locale/fr";
import { FunctionComponent, useContext, useState } from "react";
import EventContext from "../../../../context/EventsContext";
import { EventType } from "../../../../shared/types/events";
import { Delete } from "@mui/icons-material";
import useRequest from "../../../../hook/useRequest";
import { useSnackbar } from "notistack";
import DeleteDialog from "../../../../components/DeleteDialog/DeleteDialog";

const EventDetailFinance: FunctionComponent = () => {
  const {
    selectedEvent: selectedEventValue,
    setSelectedEvent,
    updateEventList,
  } = useContext(EventContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const { createRequest, deleteRequest } = useRequest("sharepoint");
  const { enqueueSnackbar } = useSnackbar();

  const selectedEvent = selectedEventValue as EventType;

  async function handleFileButton() {
    if (selectedEvent.financeFile) {
      window.open(selectedEvent.financeFile.shareLink);
    } else {
      handleFileCreation();
    }
  }

  async function handleFileCreation() {
    try {
      setIsLoading(true);
      const response = await createRequest({ id: selectedEvent._id });
      setSelectedEvent({ ...selectedEvent, financeFile: response });
      updateEventList({ skipReload: true });
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      if (err.response.status === 409) {
        enqueueSnackbar(
          "Un fichier avec le même nom existe déjà sur le sharpeoint",
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(err.response?.data?.message, {
          variant: "error",
        });
      }
      setIsLoading(false);
    }
  }

  async function handleFileDelete() {
    try {
      setIsLoading(true);
      const rep = await deleteRequest(
        selectedEvent.financeFile?.id,
        selectedEvent._id
      );
      setSelectedEvent({ ...selectedEvent, financeFile: null });
      updateEventList({ skipReload: true });
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar("Fichier inexistant ou fichier en utilisation", {
        variant: "error",
      });

      setIsLoading(false);
    }
  }

  return (
    selectedEvent && (
      <Stack spacing={2} alignItems={"flex-start"}>
        <ButtonGroup>
          <Button
            onClick={handleFileButton}
            variant="contained"
            color="secondary"
            sx={{ width: 250 }}
            disabled={isLoading}
          >
            {isLoading
              ? selectedEvent.financeFile
                ? "suppression du fichier..."
                : "fichier en création..."
              : selectedEvent.financeFile
              ? "Ouvrir le suivi de projet"
              : "Créer un suivi de projet"}
          </Button>
          <Button
            onClick={() => [setOpen(true)]}
            disabled={selectedEvent.financeFile ? false : true}
          >
            <Delete />
          </Button>
        </ButtonGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedEvent.isBilled}
              onClick={() => {
                setSelectedEvent({
                  ...selectedEvent,
                  isBilled: !selectedEvent.isBilled,
                });
              }}
            />
          }
          label={"Évènement facturé"}
        ></FormControlLabel>
        <DeleteDialog
          open={open}
          setOpen={setOpen}
          itemName="le fichier finance"
          onDelete={handleFileDelete}
        />
      </Stack>
    )
  );
};

export default EventDetailFinance;
