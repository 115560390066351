export const fr: any = {
  users: "Utilisateurs",
  locations: "Lieux",
  events: "Événements",
  planning: "Planning",
  settings: "Paramètres",
  get: "obtenu",
  delete: "supprimé",
  add: "ajouté",
  update: "modifié",
  sharepoint: "fichier finance",
  projects: "Projets"
};
