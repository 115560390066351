import React, { FC } from "react";

import { Box, Divider, IconButton, Stack } from "@mui/material";
import useOnClickListener from "./useOnClickListener";
import pluginList from "./ToolbarIconList";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface ToolbarInterface {
  onAttachementClick(): void;
}

const ToolbarPlugin: FC<ToolbarInterface> = (props) => {
  const { onClick, selectedEventTypes } = useOnClickListener();

  return (
    <Stack direction={"row"}>
      {pluginList.map((v, i) => {
        return (
          <IconButton
            key={i}
            onClick={() => {
              onClick(v.event);
            }}
            color={selectedEventTypes.includes(v.event) ? "primary" : undefined}
          >
            {<v.icon></v.icon>}
          </IconButton>
        );
      })}
      <Box sx={{ p: 0.5 }}>
        <Divider orientation="vertical" />
      </Box>
      <IconButton onClick={props.onAttachementClick}>
        <AttachFileIcon />
      </IconButton>
    </Stack>
  );
};

export default ToolbarPlugin;
