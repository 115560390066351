import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import { FunctionComponent } from "react";
import { EventMember } from "../../../../../shared/types/events";

interface TeamBoxProps {
  jobMissing: boolean;
  job: string;
  memberList: EventMember[];
}

const JobBox: FunctionComponent<TeamBoxProps> = (props) => {
  const theme = useTheme();

  const { jobMissing, job, memberList } = { ...props };

  return (
    <Stack
      minHeight={80}
      bgcolor={
        jobMissing
          ? theme.palette.grey[600] + "33"
          : theme.palette.secondary.main + "8C"
      }
      borderRadius={3}
      fontWeight={200}
      textTransform={"uppercase"}
    >
      <Typography
        borderRadius={3}
        whiteSpace={"nowrap"}
        fontSize={"0.65rem"}
        textAlign={"center"}
        fontWeight={800}
        color={"white"}
        bgcolor={
          jobMissing
            ? theme.palette.grey[600] + "33"
            : theme.palette.secondary.main
        }
        border={
          jobMissing
            ? "solid 2px " + theme.palette.grey[800] + "33"
            : "solid 2px" + theme.palette.secondary.main
        }
        p={1}
      >
        {job}
      </Typography>
      <Stack
        px={2}
        minWidth={110}
        flexGrow={1}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        columnGap={1}
      >
        {memberList.map((member, i) => {
          return (
            <Stack direction={"row"} alignItems={"center"} key={i}>
              {member.name !== "" && (
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={member.userInfo ? member.userInfo.photo : ""}
                ></Avatar>
              )}
              <Typography
                key={i}
                fontSize={"0.8rem"}
                textAlign={"center"}
                borderRadius={5}
                px={1}
                fontWeight={member.confirmed ? 100 : 800}
                color={member.confirmed ? "white" : theme.palette.primary.dark}
              >
                {member.name === ""
                  ? "?"
                  : member.userInfo
                    ? member.userInfo.nickname
                      ? member.userInfo.nickname
                      : member.userInfo.username
                    : member.name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default JobBox;
