import { Divider, Fade, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import sadIcon from "../../../assets/sad.png";
import EventContext from "../../../context/EventsContext";
import EventDetailGeneral from "./EventDetailGeneral/EventDetailGeneral";
import EventDetailHeader from "./EventDetailHeader/EventDetailHeader";
import EventDetailTeam from "./EventDetailTeam/EventDetailTeam";
import EventDetailSummary from "./EventDetailSummary/EventDetailSummary";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import ArticleIcon from "@mui/icons-material/Article";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import EventDetailAnalytic from "./EventDetailAnalytics/EventDetailAnalytics";
import SavingsIcon from "@mui/icons-material/Savings";
import useRequest from "../../../hook/useRequest";
import EventDetailFinance from "./EventDetailFinance/EventDetailFinance";

interface EventDetailProps {
  setOpenDetail(value: boolean): void;
  isAddMode: boolean;
  setIsAddMode(value: boolean): void;
}

const EventDetail: FunctionComponent<EventDetailProps> = (props) => {
  const { selectedEvent } = useContext(EventContext);

  const { isAddMode, setIsAddMode, setOpenDetail } = {
    ...props,
  };

  const [error, setError] = useState<boolean>(false);

  const [selectedMenu, setSelectedMenu] = useState<number>(0);

  const handleMenuChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedMenu(newValue);
  };

  useEffect(() => {
    setError(false);
    if (isAddMode) {
      setSelectedMenu(0);
    }
  }, [isAddMode]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        overflow: "auto",
      }}
    >
      {selectedEvent ? (
        <>
          <EventDetailHeader
            setIsAddMode={setIsAddMode}
            isAddMode={isAddMode}
            setOpenDetail={setOpenDetail}
            setError={setError}
          ></EventDetailHeader>
          <Divider />
          <Tabs
            variant="scrollable"
            value={selectedMenu}
            onChange={handleMenuChange}
            sx={{
              height: 65,
              minHeight: 65,
            }}
          >
            <Tab label={"général"} icon={<InfoIcon fontSize="small" />}></Tab>
            <Tab label={"équipe"} icon={<GroupsIcon fontSize="small" />}></Tab>
            <Tab label={"brief"} icon={<ArticleIcon fontSize="small" />}></Tab>
            <Tab
              label={"stats"}
              icon={<AnalyticsIcon fontSize="small" />}
            ></Tab>
          </Tabs>
          <Stack
            px={2}
            py={selectedMenu === 3 ? 0 : 2}
            flexGrow={1}
            overflow={"auto"}
          >
            {selectedMenu === 0 && (
              <EventDetailGeneral
                setSelectedMenu={setSelectedMenu}
                error={error}
              />
            )}
            {selectedMenu === 1 && <EventDetailTeam />}
            {selectedMenu === 2 && <EventDetailSummary />}
            {selectedMenu === 3 && <EventDetailAnalytic />}
            {selectedMenu === 4 && <EventDetailFinance />}
          </Stack>
        </>
      ) : (
        <Stack justifyContent={"center"} flexGrow={1} alignItems={"center"}>
          <Fade in={true}>
            <Typography textAlign={"center"} fontSize={"1rem"}>
              Clique sur une card pour afficher les détails
              <br />
              <img src={sadIcon} alt=":(" width={50}></img>
            </Typography>
          </Fade>
        </Stack>
      )}
    </Stack>
  );
};

export default EventDetail;
