import {
  Autocomplete,
  ButtonGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { defaultMember } from "../../../../shared/default";
import { CheckBoxOutlineBlank, Delete, Menu } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import EventDetailTeamBox from "./EventDetailTeamBox/EventDetailTeamBox";
import UseJobOptions from "../../../../hook/useJobOptions";
import EventContext from "../../../../context/EventsContext";
import UserContext from "../../../../context/UsersContext";
import { EventType } from "../../../../shared/types/events";
import { alphaValue } from "../../../../shared/const";

interface EventDetailTeamListProps {}

const EventDetailTeamList: FunctionComponent<EventDetailTeamListProps> = (
  props
) => {
  const theme = useTheme();

  const { usersList } = useContext(UserContext);

  const jobOptions = UseJobOptions(usersList);
  const [headProjectOptions, setHeadProjectOptions] = useState<any>([
    {
      name: "",
      id: "",
    },
  ]);

  const { selectedEvent: selectedEventValue, setSelectedEvent } =
    useContext(EventContext);
  const selectedEvent = selectedEventValue as EventType;

  const [eventManager, setEventManager] = useState<{
    name: string;
    id: string;
  }>({ name: "", id: "" });

  const [focusIndex, setFocusIndex] = useState<number>(-1);

  const handleBlur = (memberIndex: number) => {
    if (selectedEvent.teams.length < memberIndex + 1) {
      setFocusIndex(-1);
    } else {
      setFocusIndex(memberIndex + 1);
    }
  };

  const handleAdd = () => {
    setSelectedEvent({
      ...selectedEvent,
      teams: [...selectedEvent.teams, { ...defaultMember }],
    });
    setFocusIndex(selectedEvent.teams.length);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const TeamCopy = [...selectedEvent.teams];
    const [removed] = TeamCopy.splice(result.source.index, 1);
    TeamCopy.splice(result.destination.index, 0, removed);
    setSelectedEvent({ ...selectedEvent, teams: TeamCopy });
  };

  useEffect(() => {
    const newOptionsList = usersList
      .filter((u) => u.jobs.includes("Chef de projet"))
      .map((u) => ({ name: u.username, id: u._id }));

    setHeadProjectOptions(newOptionsList);
  }, [usersList]);

  useEffect(() => {
    setEventManager({
      name: selectedEvent.projectManager,
      id: selectedEvent.projectManagerId,
    });
  }, [selectedEvent.projectManager, selectedEvent.projectManagerId]);

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        borderRadius={4}
        spacing={2}
        bgcolor={theme.palette.secondary.light + alphaValue[10]}
        p={2}
        border={"2px solid" + theme.palette.secondary.light}
      >
        <Typography fontWeight={300}>Responsable d`&apos;évènement</Typography>
        <Autocomplete
          value={eventManager}
          options={headProjectOptions}
          sx={{ flexGrow: 1 }}
          size="small"
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option: any, value) =>
            option.name === value.name || option.name === ""
          }
          onBlur={() => {
            console.log(eventManager);
            setSelectedEvent({
              ...selectedEvent,
              projectManager: eventManager ? eventManager.name : "",
              projectManagerId: eventManager ? eventManager.id : "",
            });
          }}
          onChange={(e, newVal: any) => {
            setEventManager(newVal);
          }}
          renderInput={(params) => (
            <TextField label={"Name"} {...params}></TextField>
          )}
        ></Autocomplete>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              spacing={1}
            >
              {selectedEvent.teams.map((member, memberIndex) => (
                <EventDetailTeamBox
                  focusIndex={focusIndex}
                  onBlur={() => {
                    handleBlur(memberIndex);
                  }}
                  jobOptions={jobOptions}
                  key={memberIndex}
                  member={member}
                  memberIndex={memberIndex}
                ></EventDetailTeamBox>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Stack
        direction={"row"}
        alignItems={"center"}
        borderRadius={4}
        spacing={2}
        bgcolor={theme.palette.primary.main + "0D"}
        p={2}
        border={"2px dashed" + theme.palette.primary.main}
      >
        <Menu fontSize="small" />
        <TextField
          size="small"
          label={"Poste"}
          sx={{ flexGrow: 1 }}
          onFocus={handleAdd}
          value={""}
        />
        <TextField size="small" disabled sx={{ flexGrow: 1 }} />
        <ButtonGroup>
          <IconButton size="small" disabled>
            <CheckBoxOutlineBlank />
          </IconButton>
          <IconButton size="small" disabled>
            <Delete />
          </IconButton>
        </ButtonGroup>
      </Stack>
    </Stack>
  );
};

export default EventDetailTeamList;
