import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import EventLayout from "./layouts/EventLayout/EventLayout";
import Dashboard from "./pages/Dashboard";
import SettingLayout from "./layouts/SettingLayout/SettingLayout";
import { EventContextProvider } from "./context/EventsContext";
import { PreferenceContextProvider } from "./context/PreferenceContext";
import { UserContextProvider } from "./context/UsersContext";
import ThemeInjector from "./components/ThemeInjector/ThemeInjector";
import CalendarLayout from "./layouts/CalendarLayout/CalendarLayout";
import { LocationContextProvider } from "./context/LocationsContext";
import ProjectLayout from "./layouts/ProjectLayout/ProjectLayout";

function App() {
  return (
    <Router>
      <EventContextProvider>
        <UserContextProvider>
          <LocationContextProvider>
            <PreferenceContextProvider>
              <ThemeInjector>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to="/dashboard/events/" />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route index path="events" element={<EventLayout />} />
                    <Route path="projects" element={<ProjectLayout />} />
                    <Route path="planning" element={<></>} />
                    <Route path="settings" element={<SettingLayout />} />
                    <Route path="calendar" element={<CalendarLayout />} />
                  </Route>
                </Routes>
              </ThemeInjector>
            </PreferenceContextProvider>
          </LocationContextProvider>
        </UserContextProvider>
      </EventContextProvider>
    </Router>
  );
}

export default App;
