import { FunctionComponent, useContext, useMemo, useState } from "react";
import EventContext from "../../../context/EventsContext";
import { defaultEvent } from "../../../shared/default";
import {
  Avatar,
  Button,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import LocationContext from "../../../context/LocationsContext";
import FilterButton from "../../../components/FilterButton/FilterButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserContext from "../../../context/UsersContext";
import { User } from "../../../shared/types/users";
import { EventLocation } from "../../../shared/types/events";
import { Add, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

interface EventSideBarProps {
  setOpenDetail(value: boolean): void;
  setIsAddMode(value: boolean): void;
}

const EventTopMenu: FunctionComponent<EventSideBarProps> = (props) => {
  const { setSelectedEvent, eventFilter, updateEventList } =
    useContext(EventContext);

  const { locationsList } = useContext(LocationContext);
  const { usersList, user } = useContext(UserContext);

  const { setIsAddMode } = {
    ...props,
  };

  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userAnchorEl);

  const selectedUser: User | undefined = useMemo(() => {
    return usersList.find((u) => eventFilter.user.includes(u._id));
  }, [eventFilter, usersList]);

  const [locationAnchorEl, setLocationAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const locationMenuOpen = Boolean(locationAnchorEl);

  const selectedLocation: EventLocation | undefined = useMemo(() => {
    return locationsList.find((l) => eventFilter.location.includes(l._id));
  }, [eventFilter, locationsList]);

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  async function handleAdd() {
    setSelectedEvent({
      ...defaultEvent,
      projectManager: user.username,
      projectManagerId: user._id,
    });
    setIsAddMode(true);
  }

  return (
    <Stack direction={"row"} mx={1} alignItems={"center"}>
      <Stack
        direction={"row"}
        columnGap={"10px"}
        flexGrow={1}
        overflow={"auto"}
        alignItems={"center"}
      >
        <FilterButton
          isFilled={
            eventFilter.location.length === 0 &&
            eventFilter.user.length === 0 &&
            eventFilter.projectManager === ""
          }
          onClick={() => {
            updateEventList({
              filter: {
                ...eventFilter,
                location: [],
                user: [],
                projectManager: "",
              },
            });
          }}
        >
          Tous
        </FilterButton>
        <FilterButton
          isFilled={eventFilter.projectManager !== ""}
          onClick={() => {
            updateEventList({
              filter: {
                ...eventFilter,
                location: [],
                user: [user._id],
                projectManager: user._id,
              },
            });
          }}
        >
          Mes évènements
        </FilterButton>
        <FilterButton
          isFilled={selectedLocation === undefined ? false : true}
          onClick={(e) => {
            setLocationAnchorEl(e.currentTarget);
          }}
        >
          {selectedLocation === undefined ? (
            <>
              Filtre par lieu <ExpandMoreIcon sx={{ ml: 2 }} />
            </>
          ) : (
            <Stack
              direction={"row"}
              alignItems={"center"}
              columnGap={"5px"}
              py={0.5}
            >
              {selectedLocation.name}
              <ExpandMoreIcon />
            </Stack>
          )}
        </FilterButton>
        <Menu
          anchorEl={locationAnchorEl}
          open={locationMenuOpen}
          onClose={() => {
            setLocationAnchorEl(null);
          }}
          sx={{ maxHeight: "500px" }}
        >
          {locationsList.map((l, i) => {
            return (
              <MenuItem
                key={i}
                sx={{ display: "flex", columnGap: "10px" }}
                onClick={() => {
                  updateEventList({
                    filter: {
                      ...eventFilter,
                      location: [l._id],
                      user: [],
                      projectManager: "",
                    },
                  });
                  setLocationAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  {eventFilter.location.includes(l._id) ? (
                    <CheckBox />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </ListItemIcon>
                <ListItemText>{l.name}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
        <FilterButton
          isFilled={selectedUser === undefined ? false : true}
          onClick={(e) => {
            setUserAnchorEl(e.currentTarget);
          }}
        >
          {selectedUser === undefined ? (
            <>
              Filtre par personne <ExpandMoreIcon sx={{ ml: 2 }} />
            </>
          ) : (
            <Stack
              direction={"row"}
              alignItems={"center"}
              columnGap={"5px"}
              py={0.5}
            >
              <Avatar sx={{ width: 25, height: 25 }} src={selectedUser.photo} />{" "}
              {selectedUser.username}
              <ExpandMoreIcon />
            </Stack>
          )}
        </FilterButton>

        <Menu
          anchorEl={userAnchorEl}
          open={userMenuOpen}
          onClose={handleUserMenuClose}
          sx={{ maxHeight: "500px" }}
        >
          {usersList.map((u, i) => {
            return (
              <MenuItem
                key={i}
                sx={{ display: "flex", columnGap: "10px" }}
                onClick={() => {
                  updateEventList({
                    filter: {
                      ...eventFilter,
                      location: [],
                      user: [u._id],
                      projectManager: "",
                    },
                  });
                  handleUserMenuClose();
                }}
              >
                <Avatar sx={{ width: 30, height: 30 }} src={u.photo} />
                {u.username}
              </MenuItem>
            );
          })}
        </Menu>
      </Stack>
      <Button
        variant="contained"
        size="small"
        sx={{ whiteSpace: "nowrap", m: 1 }}
        onClick={handleAdd}
      >
        <Hidden smDown>Créer un évènement</Hidden>
        <Hidden smUp>
          <Add />
        </Hidden>
      </Button>
    </Stack>
  );
};

export default EventTopMenu;
