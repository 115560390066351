import {
  Stack,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import { EventType } from "../../../../shared/types/events";
import {
  Delete,
  MoreVert,
  CopyAll,
  ArrowDropDown,
  Send,
} from "@mui/icons-material";
import { FunctionComponent, useContext, useState } from "react";
import EventContext from "../../../../context/EventsContext";
import useRequest from "../../../../hook/useRequest";
import DeleteDialog from "../../../../components/DeleteDialog/DeleteDialog";

interface EventDetailHeaderProps {
  setOpenDetail(value: boolean): void;
  isAddMode: boolean;
  setIsAddMode(value: boolean): void;
  setError(value: boolean): void;
}

const EventDetailHeader: FunctionComponent<EventDetailHeaderProps> = (
  props
) => {
  const { isAddMode, setIsAddMode, setError } = {
    ...props,
  };

  const { selectedEvent, setSelectedEvent, updateEventList } =
    useContext(EventContext);

  const [moreOptionMenuAnchorEl, setMoreOptionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const isMoreOptionMenuOpen = Boolean(moreOptionMenuAnchorEl);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const isActionMenuOpen = Boolean(actionMenuAnchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { updateRequest, deleteRequest, createRequest } = useRequest("events");

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMoreOptionMenuAnchorEl(null);
  };

  function errorCheck(setting: EventType | null) {
    if (setting && (setting.locationName === "" || setting.name === "")) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  }

  async function handleSave(options?: { sendNotifications?: boolean }) {
    if (!errorCheck(selectedEvent) && selectedEvent) {
      const r = await updateRequest(selectedEvent._id, {
        ...selectedEvent,
        sendNotifications: options?.sendNotifications ? true : false,
      });
      if (r) {
        updateEventList({ skipReload: true });
        setSelectedEvent(null);
      }
    }
  }

  async function handleAdd(options?: { sendNotifications?: boolean }) {
    if (!errorCheck(selectedEvent)) {
      const r = await createRequest({
        ...selectedEvent,
        sendNotifications: options?.sendNotifications ? true : false,
      });
      if (r) {
        updateEventList({ skipReload: true });
        setIsAddMode(false);
      }
    }
  }
  async function handleDelete() {
    if (selectedEvent) {
      const r = selectedEvent.isDeleted
        ? await deleteRequest(selectedEvent._id)
        : await updateRequest(selectedEvent._id, { isDeleted: true });
      if (r) {
        updateEventList({ skipReload: true });
      }
      setSelectedEvent(null);
      setMoreOptionMenuAnchorEl(null);
    }
  }

  function handleDuplicate() {
    setIsAddMode(true);
    setMoreOptionMenuAnchorEl(null);
    if (selectedEvent) {
      setSelectedEvent({ ...selectedEvent, _id: undefined });
    }
  }

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1} px={2} py={0.5}>
      {(selectedEvent || isAddMode) && (
        <>
          <Typography>
            {isAddMode ? "Créer un événement" : "Modifier un évènement"}
          </Typography>
          {!isAddMode && (
            <IconButton size="small" onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
          )}
          <Menu
            anchorEl={moreOptionMenuAnchorEl}
            open={isMoreOptionMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDuplicate}>
              <ListItemIcon>
                <CopyAll />
              </ListItemIcon>
              <ListItemText>Dupliquer</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>
                {selectedEvent?.isDeleted
                  ? "Supprimer Définitivement"
                  : "Supprimer"}
              </ListItemText>
            </MenuItem>
          </Menu>
          <Box flexGrow={1}></Box>
          <ButtonGroup size="small" variant="contained" color="secondary">
            {isAddMode ? (
              <Button
                onClick={() => {
                  handleAdd();
                }}
              >
                Ajouter
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleSave();
                }}
              >
                Modifier
              </Button>
            )}
            <Button
              onClick={(e) => {
                setActionMenuAnchorEl(e.currentTarget);
              }}
              size="small"
              variant="contained"
              color="secondary"
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Menu
            anchorEl={actionMenuAnchorEl}
            open={isActionMenuOpen}
            onClose={() => {
              setActionMenuAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                isAddMode
                  ? handleAdd({ sendNotifications: true })
                  : handleSave({ sendNotifications: true });
              }}
            >
              <ListItemIcon>
                <Send />
              </ListItemIcon>
              <ListItemText>
                {isAddMode ? "Ajouter" : "Modifier"} avec notifications
              </ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
      <DeleteDialog
        itemName={selectedEvent ? "l'évènement " + selectedEvent.name : ""}
        setOpen={setOpenDeleteDialog}
        open={openDeleteDialog}
        onDelete={handleDelete}
      />
    </Stack>
  );
};

export default EventDetailHeader;
