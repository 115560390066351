import {
  Avatar,
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import { AnalyticsType, EventMember } from "../../../../../shared/types/events";

import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import HubIcon from "@mui/icons-material/Hub";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SensorsIcon from "@mui/icons-material/Sensors";

interface AnalyticBoxProps {
  analytic: AnalyticsType;
}

const AnalyticBox: FunctionComponent<AnalyticBoxProps> = (props) => {
  const theme = useTheme();

  const FieldList = {
    network: {
      name: "plateforme",
      icon: HubIcon,
    },
    channelName: { name: "nom de chaine", icon: PhotoCameraFrontIcon },
    hoursWatched: {
      name: "heure regardés",
      icon: QueryBuilderIcon,
    },
    peakViewers: {
      name: "pique de viewvers",
      icon: TrendingUpIcon,
    },
    averageCCV: {
      name: "average ccv",
      icon: MultilineChartIcon,
    },
    views: {
      name: "moyenne de viewvers",
      icon: RemoveRedEyeIcon,
    },
    airTime: {
      name: "air time",
      icon: SensorsIcon,
    },
  };

  const isMissing = useMemo(() => {
    return (
      Object.values(props.analytic).filter((v) => {
        return ["", 0].includes(v);
      }).length > 0
    );
  }, [props.analytic]);

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1,
        display: "flex",
        borderRadius: 3,
        backgroundColor: isMissing
          ? theme.palette.background.paper + "33"
          : theme.palette.secondary.main + "33",
      }}
    >
      <Stack
        borderRadius={3}
        direction={"row"}
        flexGrow={1}
        spacing={1}
        justifyContent={"space-evenly"}
      >
        {Object.entries(FieldList).map(([k, v]) => {
          const value = props.analytic[k as keyof AnalyticsType];

          const isEmpty = ["", 0].includes(value);

          return (
            <Stack alignItems={"center"} spacing={1} flexGrow={1} width={150} key={k}>
              <v.icon />
              <Stack flexGrow={1}>
                <Typography
                  fontWeight={800}
                  textAlign={"center"}
                  sx={{
                    color: isEmpty ? theme.palette.primary.dark : "",
                  }}
                >
                  {isEmpty
                    ? "?"
                    : ["airTime", "hoursWatched"].includes(k)
                    ? value.toString().includes(".")
                      ? value.toString().replace(".", "h")
                      : value + "h"
                    : value}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default AnalyticBox;
