import { Button, Stack } from "@mui/material";
import { EventLocation } from "../../../shared/types/events";
import { FunctionComponent,  useContext, useEffect, useState } from "react";
import LocationGrid from "./LocationGrid";
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import useRequest from "../../../hook/useRequest";
import LocationContext from "../../../context/LocationsContext";
import LocationDialog from "./LocationDialog";

interface LocationSettingProps {}

const LocationSetting: FunctionComponent<LocationSettingProps> = ({}) => {
  const [selectedLocation, setSelectedLocation] = useState<EventLocation | null>(null);

  const [openSettingDialog, setOpenSettingDialog] = useState<boolean>(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { locationsList, setLocationsList, loadLocationsListDB } = useContext(LocationContext);

  const { deleteRequest } = useRequest("locations");

  async function handleDelete() {
    if (!selectedLocation) {
      return;
    }
    const r: any = await deleteRequest(selectedLocation._id!);
    if (r) {
      setLocationsList(locationsList.filter((v) => v._id !== selectedLocation._id));
    }
  }

  function handleAdd() {
    setSelectedLocation(null);
    setOpenSettingDialog(true);
  }

  useEffect(() => {
    loadLocationsListDB();
  }, []);

  return (
    <Stack flexGrow={1}>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} m={1}>
        <Button variant="contained" color="secondary" onClick={handleAdd}>
          Ajouter un lieu
        </Button>
      </Stack>
      <LocationGrid
        setOpenEditDialog={setOpenSettingDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        setSelectedLocation={setSelectedLocation}
      ></LocationGrid>
      <DeleteDialog
        setOpen={setOpenDeleteDialog}
        open={openDeleteDialog}
        itemName={selectedLocation ? selectedLocation.name : ""}
        onDelete={handleDelete}
      />
      <LocationDialog
        setOpen={setOpenSettingDialog}
        open={openSettingDialog}
        selectedLocation={selectedLocation}
      ></LocationDialog>
    </Stack>
  );
};

export default LocationSetting;
