import {
  AppBar,
  Button,
  Hidden,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FunctionComponent, useContext } from "react";
import gozuprodH from "../../../assets/gozuprod_h.png";
import gozuprodV from "../../../assets/gozuprod_v.png";
import UserContext from "../../../context/UsersContext";
import AvatarMenu from "./AvatarMenu/AvatarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { headerMenuHeight } from "../../../shared/const";
import SearchBar from "./SearchBar/SearchBar";
import { fr } from "../../../translation/translation";

const HeaderBar: FunctionComponent = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: headerMenuHeight,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        px: 1,
        columnGap: 3,
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <button
          style={{
            backgroundColor: "inherit",
            border: "0",
          }}
          onClick={() => {
            navigate("/dashboard/events");
          }}
        >
          <img
            style={{ cursor: "pointer", maxHeight: 35, maxWidth: 150 }}
            src={
              useMediaQuery(theme.breakpoints.down("md"))
                ? gozuprodV
                : gozuprodH
            }
            alt="GOZUPROD"
          ></img>
        </button>
      </Stack>
      <Hidden smDown>
        <Stack flexGrow={1} direction={"row"} spacing={2}>
          {["events"].map((name, key) => {
            return (
              <Button
                key={key}
                onClick={() => {
                  navigate("/dashboard/" + name);
                }}
                sx={{
                  transition: "background-color 0.5s",
                  color:
                    location.pathname === "/dashboard/" + name
                      ? "main.primary"
                      : "grey.300",
                }}
              >
                {fr[name]}
              </Button>
            );
          })}
        </Stack>
      </Hidden>
      <Stack direction={"row"} flexGrow={1}>
        <SearchBar />
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"} flexGrow={1}>
        <AvatarMenu photo={user.photo} />
      </Stack>
    </AppBar>
  );
};

export default HeaderBar;
