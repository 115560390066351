import {
  CheckBoxOutlined,
  CheckBoxOutlineBlank,
  Delete,
  Menu,
} from "@mui/icons-material";
import {
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  useTheme,
  ButtonGroup,
  useMediaQuery,
} from "@mui/material";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Draggable } from "@hello-pangea/dnd";
import { EventMember, EventType } from "../../../../../shared/types/events";
import EventContext from "../../../../../context/EventsContext";
import UserContext from "../../../../../context/UsersContext";

interface EventDetailTeamBoxProps {
  memberIndex: number;
  member: EventMember;
  onBlur(): void;
  jobOptions: string[];
  focusIndex: number;
}

const EventDetailTeamBox: FunctionComponent<EventDetailTeamBoxProps> = ({
  memberIndex,
  member,
  jobOptions,
  focusIndex,
  onBlur,
}) => {
  const theme = useTheme();

  const { usersList } = useContext(UserContext);

  const jobFieldRef = useRef<HTMLDivElement>(null);

  const { selectedEvent: selectedEventValue, setSelectedEvent } =
    useContext(EventContext);
  const selectedEvent = selectedEventValue as EventType;

  const [name, setName] = useState<{ name: string; type: string }>({
    name: "",
    type: "",
  });
  const [job, setJob] = useState<string | null>(null);

  const [nameOptions, setNameOptions] = useState<
    {
      name: string;
      type: string;
    }[]
  >([]);

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleNameEdit = (newName: string | null) => {
    setSelectedEvent({
      ...selectedEvent,
      teams: selectedEvent.teams.map((v, i) => {
        if (i === memberIndex) {
          const user = usersList.find((v) => v.username === newName);
          return {
            ...v,
            name: user ? user.username : "",
            userId: user ? user._id : "",
          };
        }
        return v;
      }),
    });
  };

  const handleJobEdit = (newVal: string | null) => {
    setSelectedEvent({
      ...selectedEvent,
      teams: selectedEvent.teams.map((v, i) => {
        return i === memberIndex ? { ...v, job: newVal ? newVal : "" } : v;
      }),
    });
  };

  const handleConfirmation = () => {
    setSelectedEvent({
      ...selectedEvent,
      teams: selectedEvent.teams.map((v, i) => {
        return i === memberIndex ? { ...v, confirmed: !v.confirmed } : v;
      }),
    });
  };

  const handleMemberDelete = (memberIndex: number) => {
    selectedEvent &&
      setSelectedEvent({
        ...selectedEvent,
        teams: selectedEvent.teams.filter((v, i) => i !== memberIndex),
      });
  };

  useEffect(() => {
    if (focusIndex === memberIndex) {
      jobFieldRef.current?.focus();
    }
  }, [focusIndex, memberIndex]);

  useEffect(() => {
    let newOptionsList = usersList.filter((u) => u.jobs.includes(member.job));
    if (newOptionsList.length === 0) {
      newOptionsList = [...usersList];
    }
    setNameOptions(
      newOptionsList.map((v) => {
        return {
          name: v.username,
          type: v.roles.includes("Admin") ? "Interne" : "Externe",
        };
      })
    );
  }, [usersList, member.job]);

  useEffect(() => {
    setJob(member.job !== "" ? member.job : null);
  }, [member.job]);

  useEffect(() => {
    setName(
      member.name !== ""
        ? {
            name: member.name,
            type: "",
          }
        : {
            name: "",
            type: "",
          }
    );
  }, [member.name]);

  return (
    <Draggable
      key={memberIndex}
      draggableId={memberIndex.toString()}
      index={memberIndex}
    >
      {(provided) => (
        <Stack
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={() => {
            const add = member.confirmed
              ? {
                  bgcolor: theme.palette.secondary.dark + "26",
                  border: "solid 2px " + theme.palette.secondary.dark,
                }
              : {
                  bgcolor: theme.palette.primary.dark + "26",
                  border: "solid 2px " + theme.palette.primary.dark,
                };

            return {
              p: 2,
              borderRadius: 4,
              ...add,
            };
          }}
          direction={"row"}
          alignItems={"center"}
          spacing={1}
        >
          <Menu fontSize={"small"} sx={{ alignSelf: "center" }} />
          <Stack
            flexGrow={1}
            spacing={2}
            direction={isMobile ? "column" : "row"}
          >
            <Autocomplete
              options={jobOptions}
              freeSolo
              value={job}
              onChange={(e, newVal: any) => {
                setJob(newVal);
              }}
              onInputChange={(e, newVal) => {
                setJob(newVal);
              }}
              size="small"
              onBlur={() => {
                handleJobEdit(job);
              }}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => (
                <TextField
                  inputRef={jobFieldRef}
                  {...params}
                  label={"Poste"}
                ></TextField>
              )}
            />
            <Autocomplete
              options={nameOptions.sort((a, b) => (a.type > b.type ? -1 : 1))}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option: any, value) =>
                option.name === value.name
              }
              value={name}
              onChange={(e, newVal: any) => {
                setName(newVal);
              }}
              onBlur={() => {
                handleNameEdit(name ? name.name : "");
              }}
              sx={{ flexGrow: 1 }}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label={"Nom"}></TextField>
              )}
            />
          </Stack>
          <ButtonGroup>
            <IconButton
              onBlur={onBlur}
              color="inherit"
              size="small"
              onClick={(e) => {
                handleConfirmation();
              }}
            >
              {member.confirmed ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
            </IconButton>
            <IconButton
              size="small"
              color="inherit"
              disableFocusRipple
              onClick={() => {
                handleMemberDelete(memberIndex);
              }}
            >
              <Delete />
            </IconButton>
          </ButtonGroup>
        </Stack>
      )}
    </Draggable>
  );
};

export default EventDetailTeamBox;
