import { FunctionComponent, useState } from "react";
import DashboardBase from "../../components/base/DashboardBase/DashboardBase";
import WorkspaceBase from "../../components/base/WorkspaceBase/WorkspaceBase";
import UsersSetting from "./UsersSetting/UsersSetting";
import LocationSetting from "./LocationSetting/LocationSetting";
import { Tab, Tabs } from "@mui/material";

const SettingLayout: FunctionComponent = () => {
  const [menu, setMenu] = useState<number>(0);

  return (
    <DashboardBase>
      <Tabs value={menu}>
        {["Utilisateurs", "Lieux"].map((v, i) => {
          return (
            <Tab
              key={i}
              sx={{ width: "10vw" }}
              onClick={() => {
                setMenu(i);
              }}
              label={v}
            />
          );
        })}
      </Tabs>
      <WorkspaceBase
        sx={{
          p: 0.5,
        }}
      >
        {menu === 0 && <UsersSetting />}
        {menu === 1 && <LocationSetting />}
      </WorkspaceBase>
    </DashboardBase>
  );
};

export default SettingLayout;
