export function normalizeString(str: string) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function getIncomingDate() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    startDate: today,
    endDate: undefined,
  };
}

export function getPastDate() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    startDate: undefined,
    endDate: today,
  };
}

export function getMonthDate(month: number) {
  const startDate = new Date();
  startDate.setMonth(month, 0);
  const endDate = new Date();
  endDate.setMonth(month + 1, 0);
  return {
    startDate: startDate,
    endDate: endDate,
    sort: "startDate_asc",
  };
}

export function getDateRange(startDate: Date, endDate: Date) {
  const dateArray = [];
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(currentDate.toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
