import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EventLocation } from "../../../shared/types/events";
import { defaultLocation } from "../../../shared/default";
import { useSnackbar } from "notistack";
import useRequest from "../../../hook/useRequest";
import LocationContext from "../../../context/LocationsContext";
import { SliderPicker } from "react-color";

interface LocationDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  selectedLocation: EventLocation | null;
}

const LocationDialog: FunctionComponent<LocationDialogProps> = (props) => {
  const { open, setOpen, selectedLocation } = { ...props };

  const [setting, setSetting] = useState<EventLocation>(defaultLocation);

  const [error, setError] = useState<boolean>(false);

  const { locationsList, setLocationsList } = useContext(LocationContext);

  const { enqueueSnackbar } = useSnackbar();

  const { createRequest, updateRequest } = useRequest("locations");

  const isAdd = useMemo(() => {
    return selectedLocation === null;
  }, [selectedLocation]);

  function handleClose() {
    setOpen(false);
  }

  function ErrorCheck(setting: EventLocation) {
    if (setting.name === "" || setting.address === "") {
      enqueueSnackbar("Champ obligatoire manquant", {
        variant: "error",
      });
      setError(true);
      return true;
    } else {
      return false;
    }
  }

  async function handleAdd() {
    if (ErrorCheck(setting)) {
      return;
    }
    const r: any = await createRequest(setting);
    if (r) {
      setLocationsList([...locationsList, { ...r }]);
      handleClose();
    }
  }

  async function handleEdit() {
    if (ErrorCheck(setting) || !selectedLocation) {
      return;
    }
    const r: any = await updateRequest(setting._id!, setting);
    if (r) {
      setLocationsList(
        locationsList.map((u, i) => {
          if (u._id === selectedLocation._id) {
            return { ...setting };
          } else {
            return { ...u };
          }
        })
      );
      handleClose();
    }
  }

  useEffect(() => {
    if (selectedLocation) {
      setSetting({ ...selectedLocation });
    } else {
      setSetting({ ...defaultLocation });
    }
  }, [selectedLocation]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {isAdd
          ? "Ajouter un nouveau lieu"
          : "Modifier le lieu " + selectedLocation?.name}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} m={2}>
          <TextField
            value={setting.name}
            error={error && setting.name === ""}
            onChange={(e) => {
              setSetting({ ...setting, name: e.currentTarget.value });
            }}
            label={"Nom"}
          />
          <TextField
            value={setting.address}
            error={error && setting.address === ""}
            onChange={(e) => {
              setSetting({ ...setting, address: e.currentTarget.value });
            }}
            label={"Adresse"}
          />
          <TextField
            value={setting.googleCalendarId}
            error={error && setting.googleCalendarId === ""}
            onChange={(e) => {
              setSetting({
                ...setting,
                googleCalendarId: e.currentTarget.value,
              });
            }}
            label={"google calendar id"}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Annuler
        </Button>
        <Button onClick={isAdd ? handleAdd : handleEdit} color="secondary">
          {isAdd ? "Ajouter" : "Modifier"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LocationDialog;
