import {
  Button,
  ButtonGroup,
  Icon,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import "dayjs/locale/fr";
import {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import EventContext from "../../../../context/EventsContext";
import { AnalyticsType, EventType } from "../../../../shared/types/events";
import { defaultEventAnalytics } from "../../../../shared/default";

import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import HubIcon from "@mui/icons-material/Hub";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SensorsIcon from "@mui/icons-material/Sensors";
import { Add, Delete } from "@mui/icons-material";

interface EventDetailAnalyticProps {}

const EventDetailAnalytic: FunctionComponent<EventDetailAnalyticProps> = () => {
  const { selectedEvent: selectedEventValue, setSelectedEvent } =
    useContext(EventContext);

  // remove the null type because cannot be possible
  const selectedEvent = selectedEventValue as EventType;

  const FieldList = {
    network: {
      name: "Plateforme",
      icon: HubIcon,
    },
    channelName: { name: "Nom de la chaine", icon: PhotoCameraFrontIcon },
    hoursWatched: {
      name: "Heures regardées",
      icon: QueryBuilderIcon,
    },
    peakViewers: {
      name: "Pic de viewers",
      icon: TrendingUpIcon,
    },
    averageCCV: {
      name: "Moyenne de viewers",
      icon: MultilineChartIcon,
    },
    views: {
      name: "Nombre de vues",
      icon: RemoveRedEyeIcon,
    },
    airTime: {
      name: "Air time",
      icon: SensorsIcon,
    },
  };

  const [setting, setSetting] = useState<AnalyticsType[]>([
    defaultEventAnalytics,
  ]);

  const [selectedChannel, setSelectedChannel] = useState<number>(0);

  function handleDelete() {
    if (selectedChannel > setting.length - 2) {
      setSelectedChannel(selectedChannel - 1);
    }
    setSelectedEvent({
      ...selectedEvent,
      analytics: setting.filter((v, i) => {
        return i !== selectedChannel;
      }),
    });
  }

  function handleAdd() {
    setSelectedEvent({
      ...selectedEvent,
      analytics: [...setting, defaultEventAnalytics],
    });
  }

  function handleSettingUpdate(updatedValue: any) {
    setSetting(
      setting.map((v, k) => {
        return k === selectedChannel ? { ...v, ...updatedValue } : v;
      })
    );
  }

  function handleUpdate() {
    setSelectedEvent({
      ...selectedEvent,
      analytics: setting.map((v) => {
        return { ...v };
      }),
    });
  }

  useEffect(() => {
    setSetting(
      selectedEvent.analytics.map((v) => {
        return { ...v };
      })
    );
  }, [selectedEvent.analytics]);

  useEffect(() => {
    if (selectedChannel > selectedEvent.analytics.length - 1) {
      setSelectedChannel(0);
    }
  }, [selectedEvent, selectedChannel]);

  return (
    selectedEvent && (
      <Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Tabs
            variant="scrollable"
            indicatorColor="secondary"
            textColor="secondary"
            value={selectedChannel}
            onChange={(e, newVal) => {
              setSelectedChannel(newVal);
            }}
            sx={{ flexGrow: 1 }}
          >
            {[
              selectedEvent.analytics.map((v, k) => {
                return <Tab key={k} label={"Diffusion " + (k + 1)}></Tab>;
              }),
            ]}
          </Tabs>
          <ButtonGroup
            variant="contained"
            color="secondary"
            sx={{ maxHeight: 40 }}
          >
            <Button onClick={handleAdd}>
              <Add />
            </Button>
            <Button onClick={handleDelete} disabled={selectedChannel === 0}>
              <Delete />
            </Button>
          </ButtonGroup>
        </Stack>
        <Stack spacing={2} py={2}>
          {Object.entries(FieldList).map(([k, v]) => {
            return (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={2}
                key={k}
              >
                <v.icon />
                <TextField
                  fullWidth
                  key={k}
                  label={v.name}
                  value={setting[selectedChannel][k as keyof AnalyticsType]}
                  onChange={(e) => {
                    handleSettingUpdate({ [k]: e.currentTarget.value });
                  }}
                  type={
                    ["channelName", "network"].includes(k) ? "string" : "number"
                  }
                  onBlur={handleUpdate}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    )
  );
};

export default EventDetailAnalytic;
