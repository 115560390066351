import {
  DarkMode,
  DisplaySettings,
  LightMode,
  Logout,
  Settings,
  SettingsBrightness,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { FunctionComponent, useContext, useState } from "react";
import PreferenceContext from "../../../../context/PreferenceContext";
import {
  themeModeEnum,
  viewModeEnum,
} from "../../../../shared/types/preference";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../../hook/useRequest";

import HideSourceIcon from "@mui/icons-material/HideSource";
import GetAppIcon from "@mui/icons-material/GetApp";
import GroupsIcon from "@mui/icons-material/Groups";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import UserContext from "../../../../context/UsersContext";

interface AvatarMenuProps {
  photo: string;
}

const AvatarMenu: FunctionComponent<AvatarMenuProps> = ({ photo }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { userPreference, setUserPreference } = useContext(PreferenceContext);

  const { user } = useContext(UserContext);

  const { customGetRequest } = useRequest("");

  function handleLogout() {
    customGetRequest("/auth/logout");
    navigate("/login");
  }

  async function handleExport() {
    const rep = await customGetRequest("/api/export/database", {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([rep]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "tiens gaetan c moi le robot de jej voici le csv kiffe bien mon frere profite de ces petites datas.csv"
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        <Avatar sx={{ height: 40, width: 40 }} src={photo}></Avatar>
      </IconButton>
      <Menu
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Stack spacing={1}>
          <Stack direction={"row"} px={1} spacing={1}>
            <DisplaySettings color="primary" />
            <Typography color="primary" fontWeight={300}>
              Menu
            </Typography>
          </Stack>
          <Divider />
          <Stack
            px={2}
            direction={"column"}
            spacing={1}
            sx={{ borderRadius: "25px" }}
          >
            <FormLabel>Mode</FormLabel>
            <ButtonGroup>
              {(["light", "system", "dark"] as themeModeEnum[]).map((v, i) => {
                return (
                  <Button
                    key={i}
                    variant={
                      v === userPreference.themeMode ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setUserPreference({ ...userPreference, themeMode: v });
                    }}
                  >
                    {v === themeModeEnum.light ? (
                      <LightMode />
                    ) : v === themeModeEnum.system ? (
                      <SettingsBrightness />
                    ) : (
                      <DarkMode />
                    )}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>
          <Stack
            px={2}
            direction={"column"}
            spacing={1}
            sx={{ borderRadius: "25px" }}
          >
            <FormLabel>View</FormLabel>
            <ButtonGroup>
              {(["off", "team", "analytic"] as viewModeEnum[]).map((v, i) => {
                return (
                  <Button
                    key={i}
                    variant={
                      v === userPreference.view ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setUserPreference({ ...userPreference, view: v });
                    }}
                  >
                    {v === viewModeEnum.off ? (
                      <HideSourceIcon />
                    ) : v === viewModeEnum.team ? (
                      <GroupsIcon />
                    ) : (
                      <AnalyticsIcon />
                    )}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>
          {["jeremy@gozulting.com", "gaetan@gozulting.com"].includes(
            user.email
          ) && (
            <MenuItem onClick={handleExport}>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText color="primary">Export CSV</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              navigate("/dashboard/settings");
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText color="primary">Paramètres</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText color="primary">Déconnexion</ListItemText>
          </MenuItem>
        </Stack>
      </Menu>
    </>
  );
};

export default AvatarMenu;
