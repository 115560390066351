import { Fade, Stack, useTheme } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { headerMenuHeight } from "../../../shared/const";

interface DashboardBaseProps {
  children?: ReactNode;
}

const DashboardBase: FunctionComponent<DashboardBaseProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Fade in={true}>
      <Stack
        height={`calc(50vh - ${headerMenuHeight}) `}
        direction={"column"}
        sx={{
          mx: 2,
          [theme.breakpoints.down("md")]: {
            mx: 0,
          },
        }}
      >
        {children}
      </Stack>
    </Fade>
  );
};

export default DashboardBase;
