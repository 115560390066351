import { Chip, Fade, Stack, Typography } from "@mui/material";
import { attachemntType, EventType } from "../../../../../shared/types/events";
import { FC, useContext } from "react";
import EventContext from "../../../../../context/EventsContext";

interface AttachementProps {
  attachement: attachemntType;
}

const Attachement: FC<AttachementProps> = ({ attachement }) => {
  const { setSelectedEvent } = useContext(EventContext);

  function handleClick() {
    window.open(attachement.fileUrl);
  }

  function handleDelete() {
    setSelectedEvent((prevState) => {
      if (!prevState) return null;
      return {
        ...prevState,
        attachments: prevState.attachments.filter((v) => v !== attachement),
      };
    });
  }

  return (
    <Fade in={true}>
      <Chip
        sx={{ p: 1 }}
        icon={<img src={attachement.iconUrl} alt={"icon"} />}
        label={attachement.fileName}
        onDelete={handleDelete}
        onClick={handleClick}
      />
    </Fade>
  );
};

export default Attachement;
